import React from 'react';
import PropTypes from 'prop-types';

import FakeTertiaryLink from './UIComponents/FakeTertiaryLink';

const ToolCard = ({ onClick, excerpt, headline, thumbnail, title, url }) => {
  return (
    <a
      href={url}
      className="mb-auto cursor-pointer"
      onClick={onClick ? onClick : null}
    >
      <article className="w-full mb-12 sm:mb-4 group overflow-hidden">
        <div className="w-full">
          <img
            src={thumbnail}
            alt={title}
            className="w-full object-cover object-fit self-start max-h-20 lg:max-h-30"
            loading="lazy"
          />
        </div>
        <div className="break-words w-full flex flex-wrap flex-col mt-4 sm:transform sm:transition-all sm:duration-150 sm:translate-y-5 sm:group-hover:translate-y-0 md:mt-3">
          <span className="w-full text-xl font-bold leading-tight mb-4 text-black-700 sm:w-11/12 md:text-2xl">
            {headline}
          </span>

          <h3 className="break-words w-full text-17 mb-2 text-black-700 sm:w-11/12 xl:text-xl">
            {title}
          </h3>
          <p className="w-full text-15 mb-6 text-black-700 sm:w-11/12 xl:text-base">
            {excerpt}
          </p>
          <FakeTertiaryLink text="Erfahren Sie Mehr" className="mr-auto" />
        </div>
      </article>
    </a>
  );
};

ToolCard.propTypes = {
  onClick: PropTypes.func,
  excerpt: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
};

export default ToolCard;
