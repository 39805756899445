import React from 'react';
import { graphql } from 'gatsby';

import ContentHeaderBgImg from '../components/ContentHeaderBgImg'
import Layout from '../components/layout';
import ModalTrigger from '../HOC/ModalTrigger';
import ToolCard from '../components/ToolCard';
import SEO from '../components/seo';

const Tools = ({ data }) => {
  const { toolpage, tools } = data.gatsby;
  const thumbnails = {
    thumbnailSmall: (toolpage.thumbnailSmall.length ? toolpage.thumbnailSmall[0].url : null),
    thumbnailMedium: (toolpage.thumbnailMedium.length ? toolpage.thumbnailMedium[0].url : null),
    thumbnailLarge: (toolpage.thumbnailLarge.length ? toolpage.thumbnailLarge[0].url : null)
  }

  return (
    <Layout>
      <SEO seomatic={toolpage.seomatic} />
      <ContentHeaderBgImg 
        title={toolpage.headline}
        text={toolpage.description}
        meta={toolpage.excerpt}
        thumbnails={thumbnails}
      />
      <div className="px-5 my-10 md:px-10 md:my-16 lg:my-20 xl:my-24">
        <div className="grid grid-cols-1 mx-auto max-w-screen-xxl sm:grid-cols-2 sm:gap-4 sm:row-gap-8 lg:grid-cols-4 ">
          {tools.map(tool => (
            <ToolCard
              key={tool.id}
              headline={tool.headline}
              title={tool.title}
              excerpt={tool.description}
              url={tool.uniformResourceLocator}
              thumbnail={tool.toolThumbnail[0].url}
            />
          ))}
          <ModalTrigger
            form
            url={toolpage.modalBlock[0].modalHook}
            title={toolpage.modalBlock[0].modalTitle}
            headline={toolpage.modalBlock[0].modalHeadline}
            text={toolpage.modalBlock[0].modalText}
          >
            <ToolCard
              headline={toolpage.checklistCard[0].cardHeadline}
              title={toolpage.checklistCard[0].cardTitle}
              excerpt={toolpage.checklistCard[0].cardExcerpt}
              thumbnail={toolpage.checklistCard[0].cardThumbnail}
            />
          </ModalTrigger>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    gatsby {
      toolpage: entry(slug: "services") {
        ... on Gatsby_toolsPage_toolsPage_Entry {
          headline
          description
          excerpt
          seomatic {
            metaTitleContainer
            metaTagContainer
          }
          thumbnailLarge {
            url
          }
          thumbnailMedium {
            url
          }
          thumbnailSmall {
            url
          }
          modalBlock {
            ... on Gatsby_modalBlock_modal_BlockType {
              modalHook
              modalTitle
              modalHeadline
              modalText
            }
          }
          checklistCard {
            ... on Gatsby_checklistCard_checklistCard_BlockType {
              cardHeadline
              cardTitle
              cardExcerpt
              cardThumbnail
            }
          }
        }
      }
      tools: entries(section: "tool") {
        ... on Gatsby_tool_tool_Entry {
          uri
          id
          headline
          title
          description
          uniformResourceLocator
          list {
            ... on Gatsby_list_item_BlockType {
              text
            }
          }
          toolThumbnail {
            url
          }
        }
      }
    }
  }
`;

export default Tools;
