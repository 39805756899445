import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useWindowWidth from '../hooks/useWindowWidth'

const ContentHeaderBgImg = ({ updated, meta, subtitle, text, title, thumbnails, white }) => {
  const width = useWindowWidth()
  let thumbnail = thumbnails.thumbnailLarge
  if (width <= 640) {
    thumbnail = thumbnails.thumbnailSmall
  } else if (width > 640 && width <= 1024) {
    thumbnail = thumbnails.thumbnailMedium
  }

  return (
    <section 
      className="px-5 py-10 min-h-40 md:min-h-80 md:px-8 md:py-16 lg:py-20 xl:py-24 bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `url('${thumbnail}')`
      }}
    >
      <div className={classNames(
        'm-auto max-w-screen-xxl',
        {
          'text-white': white,
        }
      )}>
        <div className="break-words lg:w-8/12 xl:w-7/12">
          <h1 className="w-5/6 mb-8 text-3.5xl font-light leading-10 md:text-5xl md:leading-12 lg:leading-16 xl:text-5.5xl">
            {title}
          </h1>
          {subtitle && <p className="text-17 w-5/6 leading-6">{subtitle}</p>}
          
          {text && (
            <h4 className="w-5/6 text-17 mt-4 mb-4 leading-6 md:w-4/6 md:mb-8 md:mt-15 xl:text-2xl">
              {text}
            </h4>
          )}
          {meta && (
            <p className="w-full block text-15 mt-2 font-normal text-black-900 md:w-4/6 xl:text-base">
              {meta}
            </p>
          )}
          {updated && (
            <p className="w-full block text-15 mt-2 font-normal text-grey-500.5 md:w-4/6 xl:text-base">
              {updated}
            </p>
          )}
        </div>
      </div>
    </section>
  )
}

ContentHeaderBgImg.propTypes = {
  meta: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  thumbnails: PropTypes.object
}

export default ContentHeaderBgImg
